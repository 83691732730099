import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import SsoBanner from '../SsoBanner';
import Button from 'components/common/Button';
import CheckboxField from 'components/common/form/CheckboxField';
import SimpleField from 'components/common/form/SimpleField';
import { useAppData } from 'hooks/useAppData';
import { useUrlParams } from 'hooks/useUrlParams';
import LanguageContext from 'Language/LanguageContext';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

const Signup = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();
    const { language } = useContext(LanguageContext);

    return (
        <>
            <h1 className="title">
                {t('auth.signup.title')}
            </h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <div className="auth-form__name-fields">
                    <Field
                        className="margin-right-16"
                        component={SimpleField}
                        label={t('auth.surname.label')}
                        name="surname"
                        type="text"
                        validate={[required]}
                    />
                    <Field
                        component={SimpleField}
                        label={t('auth.name.label')}
                        name="name"
                        type="text"
                        validate={[required]}
                    />
                </div>
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[emailFormat, required]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.password.label')}
                    name="password"
                    type="password"
                    validate={[required]}
                />
                <Field
                    component={CheckboxField}
                    name="cgu"
                    validate={required}
                >
                    <>
                        {t('auth.cgu.label.firstPart')}
                        &nbsp;
                        <Link className="link" to={`/${language}/tos`}>{t('cgu.link.title')}</Link>
                        &nbsp;
                        {t('auth.cgu.label.secondPart')}
                        &nbsp;
                        <Link className="link" to={`/${language}/confidentiality`}>{t('cgu.link.policy')}</Link>
                        &nbsp;
                        {t('auth.cgu.label.thirdPart')}
                    </>
                </Field>
                <Field
                    component={CheckboxField}
                    name="isTester"
                >
                    {t('auth.isTester.label')}
                </Field>
                <Button
                    className="button--block button--black base-margin"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('auth.signup.submit')}
                    submit
                />
                <a
                    aria-label='Google'
                    className="auth-form__google-button"
                    href={`${AUTH_URL}/api/auth/vasco-v2/google`}
                >
                    <Button
                        className="button--block button--outline base-margin"
                        img="/assets/icons/google.svg"
                        text={t('auth.google')}
                    />
                </a>
                <div className="base-margin auth-form__redirect-link">
                    <span>{t('auth.signup.alreadyHaveAnAccount')}</span>
                    <button
                        className="link strong"
                        onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                        type="button"
                    >
                        {t('auth.signup.switchToLogin')}
                    </button>
                </div>
            </form>
        </>
    );
};

Signup.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'signupForm',
    initialValues: { cgu: false, isTester: true },
})(Signup);
