import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import HomeCard from 'components/common/HomeCard';
import './style.scss';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';

const Card1 = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/self-knowledge.svg"
            text={t('home.card1.content')}
            title={t('home.card1.title')}
        />
    );
};

const Card2 = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/files.svg"
            text={t('home.card2.content')}
            title={t('home.card2.title')}
        />
    );
};

const Card3 = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/carried.svg"
            text={t('home.card3.content')}
            title={t('home.card3.title')}
        />
    );
};

const HomeContent = ({ onClick }) => {
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const { INNOVATION_SITE_URL, MARCO_V4_URL, HESTER_URL, FREYA_URL } = useAppData();

    return (
        <main role="main">
            <div className="home-content__container">
                <div className="home-content__cards">
                    <div className="home-content__cards__text">
                        <h2 className="home-content__cards__text__title">{t('home.content.cards_text.title')}</h2>
                        <p className="home-content__cards__text__description">{t('home.content.cards_text.text')}</p>
                        <Button
                            className="button button--black"
                            onClick={() => {
                                onClick();
                            }}
                            text={t('home.start_button')}
                        />
                    </div>
                    <div className="home-content__cards__wrapper">
                        <Card1 />
                        <Card2 />
                        <Card3 />
                    </div>
                </div>
                <div className="home-content__what-to-expect">
                    <div className="home-content__what-to-expect__description-wrapper">
                        <h2 className="title home-content__what-to-expect__title">
                            {t('home.content.what-to-expect.title')}
                        </h2>
                        <p><Trans i18nKey="home.content.what-to-expect.text" /></p>
                    </div>
                    <img
                        alt=""
                        className="home-content__what-to-expect__illustration"
                        src="/assets/vasco-steps.gif"
                    />
                </div>
                <div className="home-content__your-way">
                    <img
                        alt=""
                        className="home-content__your-way__illustration"
                        src="/assets/your-way.svg"
                    />
                    <div className="home-content__your-way__description-wrapper">
                        <h2 className="home-content__your-way__title">
                            {t('home.content.your_way.title')}
                        </h2>
                        <p>{t('home.content.your_way.intro')}</p>
                        <ul>
                            <li>
                                <Trans i18nKey="home.content.your_way.marco" />
                                <a href={MARCO_V4_URL}>{t('common.marco')}</a>
                            </li>
                            <li>
                                <Trans i18nKey="home.content.your_way.freya" />
                                <a href={FREYA_URL}>{t('common.freya')}</a>
                            </li>
                            <li>
                                <Trans i18nKey="home.content.your_way.hester" />
                                <a href={HESTER_URL}>{t('common.hester')}</a>
                            </li>
                        </ul>
                        <Button
                            className="button button--black"
                            onClick={() => {
                                window.location.href = INNOVATION_SITE_URL;
                            }}
                            text={t('home.content.your_way.action')}
                        />
                    </div>
                </div>
                <div className="home-content__banner">
                    <p className="home-content__banner__title">{t('home.banner.start')}</p>
                    <Button
                        className="button button--black"
                        onClick={() => {
                            onClick();
                        }}
                        text={t('home.banner.action')}
                    />
                </div>
                <div className="home-content__mission">
                    {!isMobile && (
                        <img
                            alt=""
                            className="home-content__mission__illustration"
                            src="/assets/anticipation.svg"
                        />
                    )}
                    <div className="home-content__mission__description">
                        <h2 className="title">
                            {t('home.footer.mission.title')}
                        </h2>
                        {/* eslint-disable-next-line react/no-danger */}
                        <p dangerouslySetInnerHTML={{
                            __html: t('home.footer.mission.content', {
                                FREYA_URL,
                                HESTER_URL,
                                MARCO_URL: MARCO_V4_URL,
                            }),
                        }}
                        />
                        <div className="home-content__mission__logos">
                            <a
                                className="home-content__mission__logo"
                                href="https://www.parisdescartes.fr/"
                            >
                                <img alt="Logo Universite Paris" src="/assets/logo-paris-descartes.svg" />
                            </a>
                            <a
                                className="home-content__mission__logo"
                                href="https://www.jobteaser.com"
                            >
                                <img alt="Logo Job Teaser" src="/assets/jobteaser-logo-black.svg" />
                            </a>
                        </div>
                    </div>
                    {isMobile && (
                        <img
                            alt=""
                            className="home-content__mission__illustration"
                            src="/assets/anticipation.svg"
                        />
                    )}
                </div>
            </div>
        </main>
    );
};

HomeContent.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default HomeContent;
